export default function scrollTo(id, behavior, offset) {
  const target = document.getElementById(id);
  const headerHeight = 82;
  const off = offset ? offset : 10;

  if(target) {
    window.scrollTo({
      top:
        target.getBoundingClientRect().top + window.scrollY - headerHeight - off,
      left: 0,
      behavior: behavior ? behavior : 'smooth',
    });
  }
}
