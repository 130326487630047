import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  textStatic: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  tagName: PropTypes.string,
  className: PropTypes.string,
  headingLevel: PropTypes.number,
};

const defaultProps = {
  tagName: 'article',
  className: '',
  headingLevel: 3,
  text: '',
  textStatic: '',
  variants: []
};

function Card({ iconSrc, title, text, textStatic, tagName, className, variants, headingLevel }) {
  const variantClasses = variants.map(variant => `Card--${variant}`);
  const classes = classNames(className, 'Card', variantClasses);
  const TagName = tagName;
  const Heading = `h${headingLevel}`;

  return (
    <TagName className={classes}>
      <div className="Card__content">
        <img src={iconSrc} className="Card__icon" alt={title} />

        <Heading className="Card__title">{title}</Heading>

        {textStatic && <div className="Card__textStatic">{textStatic}</div>}

        <p className="Card__text">{text}</p>
      </div>
    </TagName>
  );
}

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
