import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

// import Button from '../Button';
import BackgroundSrc from '../../img/svg/pageServicesSweden/hero_bg.svg';
import Illustration from '../../img/svg/pageServicesSweden/hero_illustration.svg';
import IllustrationBarsSrc from '../../img/svg/pageServices/hero_bars.svg';

function HomeHeroA({ title, text }) {
  const [isMainSVGLoaded, setIsMainSVGLoaded] = useState(false);

  const classNameBackground = classNames('HomeHeroA__background', {
    'HomeHeroA__background--noImage': !isMainSVGLoaded
  });

  return (
    <section className="HomeHeroA">
      <div className={classNameBackground}>
        <SVG
          src={BackgroundSrc}
          uniquifyIDs={false}
          className="HomeHeroA__background-image"
          onError={error => console.log(error.message)}
          onLoad={(src, hasCache) => setIsMainSVGLoaded(true)}
        />
      </div>

      <div className="HomeHeroA__container">
        <div className="HomeHeroA__content">
          <h1 className="HomeHeroA__title">{title}</h1>
          <p className="HomeHeroA__text">
            <ReactMarkdown children={text} rehypePlugins={[rehypeRaw]} />
          </p>
        </div>

        <div className="HomeHeroA__illustration">
          <img
            src={Illustration}
            alt="servers"
            className="HomeHeroA__image"
          />
          <SVG src={IllustrationBarsSrc} className="HomeHeroA__bars" />
        </div>
      </div>
      
    </section>
  );
}

export default HomeHeroA;
