import React from 'react';

const ReferenceShort = ({
  avatar,
  text,
  author,
  authorTitle,
  authorLogo
}) => {
  return (
    <div className="ReferenceShort">
      <div className="ReferenceShort__header">
        <div className="ReferenceShort__avatar" style={{backgroundImage: `url(${avatar})`}}></div>
      </div>
      <div className="ReferenceShort__content">
        <p className="ReferenceShort__text">
          {text}
        </p>
        {(author.length > 0 || authorTitle.length > 0 || authorLogo.length > 0) &&
          <div className="ReferenceShort__content__bottom">
            <div className="ReferenceShort__author">{author}</div>
            <div className="ReferenceShort__authorTitle">{authorTitle}</div>
          </div>
        }
      </div>
    </div>
  )
}

export default ReferenceShort;
