import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import scrollTo from '../helpers/scrollTo';

import DataTestimonials from '../data/testimonials.json';
import DataProjects from '../data/projects.json';
import DataClients from '../data/clients.json';

import Layout from '../components/Layout';
import IconedText from '../components/IconedText';
import Card from '../components/Card';
import Markdown from '../components/Markdown';
import ProjectItem from '../components/ProjectItem';
import ReferenceSwiper from '../components/ReferenceSwiper';
import Clients from '../components/sections/Clients';
import HomeHeroA from '../components/sections/HomeHeroA';

import BackgroundClientsSrc from '../img/svg/pageHome/conference_bg.svg';
import ServicesBgSrc from '../img/svg/pageSolutions/services_bg.svg';

export const SwedenPageTemplate = ({ 
  hero,
  clients, 
  services, 
  testimonial,
  project,
  profits,
  location
}) => {

  const dataProjects =
    DataProjects.items.filter(o => project.projects.find(o2 => o.id === o2.value))
    .map(item => {
      let current = item.testimonial && item.testimonial[0] && item.testimonial[0].value;
      let testimonial =
        DataTestimonials.projects.find(o => current === o.id);

      return {...item, ...{testimonial: testimonial}}
  });

  const clientsFiltered =
    DataClients.items && DataClients.items.length && clients && clients.logos && clients.logos.length
      ? DataClients.items.filter(o => clients.logos.find(o2 => o.id == o2.value)) : null; // must be ==

  const classNameLogos = classNames('Clients--noPadding', { 
    'Clients--oneRow': clientsFiltered.length > 4 
  });

  if(location.hash === '#contact') {
    setTimeout(() => {
      scrollTo('contact', 'auto')
    }, 100)
  }

  return (
    <div className="ServicesSweden">
      <HomeHeroA 
        title={hero.title}
        text={hero.text}
      />

      <section className="Services__cards">
        <div className="Services__container">
          <ul className="Services__items">
            {services.items.map((item, i) => (
              <IconedText
                key={i}
                className="Services__item"
                tagName="li"
                iconSrc={item.icon && item.icon.publicURL}
                title={item.name}
                variants={['large']}
              >
                <Markdown content={item.text} />
              </IconedText>
            ))}
          </ul>
        </div>
      </section>

      <section className="ServicesSweden__clients">
        <div className="Realtime__clients">
          <div className="Realtime__container">
            <h2 className="Realtime__clients__title">{clients.title}</h2>
            <div className="Realtime__clients__items">
              <Clients clients={clientsFiltered} className={classNameLogos} />
            </div>
          </div>
        </div>
      </section>

      <section className="ServicesSweden__projects Services__projects">
        <div className="Services__container">
          <h2 className="Services__projects__title">{project.title}</h2>
          {dataProjects.map((item, i) => (
            <div key={i} className="Services__projects__item">
              <ProjectItem
                title={item.title}
                imageSrc={item.image}
                buttons={item.buttons}
                technologies={item.technologies}
                reference={item.testimonial}
                titleWithText={true}
                reverse={i % 2 === 0 ? true : false}
              >
                {item.text}
              </ProjectItem>
            </div>
          ))}
        </div>
      </section>

      <section className="ServicesSweden__testimonials Services__testimonials">
        <div className="Services__testimonials__background">
          <SVG
            src={BackgroundClientsSrc}
            uniquifyIDs={false}
            className="Services__testimonials__background-image"
          />
        </div>
        <div className="Services__testimonials__container">
          <h2 className="Services__testimonials__title">{testimonial.title}</h2>
          <ReferenceSwiper data={DataTestimonials.sweden} />
        </div>
      </section>

      <section className="ServicesSweden__services">
        <div className="ServicesSweden__services__background">
          <SVG
            src={ServicesBgSrc}
            uniquifyIDs={false}
            className="ServicesSweden__services__background-image"
          />
        </div>

        <div className="ServicesSweden__services__container">
          <div className="ServicesSweden__services__content">
            <h2 className="ServicesSweden__services__title">{profits.heading}</h2>

            <p className="ServicesSweden__services__text">
              {profits.text}
            </p>
          </div>

          <ul className="ServicesSweden__services__items">
            {profits && profits.items &&
              profits.items.map((item, i) => (
                <Card
                  key={i}
                  tagName="li"
                  className="ServicesSweden__services__item"
                  iconSrc={item.icon.publicURL || item.icon}
                  title={item.name}
                  text={item.text}
                />
              ))}
          </ul>
        </div>
      </section>
    </div>
  )
};

const SwedenPage = (props) => {
  const { frontmatter } = props.data.markdownRemark;
  const mergedData = {...frontmatter, ...{ location: props.location }};

  return (
    <Layout 
      variants={['backgrounded']} 
      meta={frontmatter.meta} 
      contactTitle="What about working together?"
    >
      <SwedenPageTemplate {...mergedData} />
    </Layout>
  );
};

SwedenPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default SwedenPage;

export const servicesPageQuery = graphql`
  query SwedenPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "hej-sverige-big-data-science-cloud-sweden-page" } }) {
      frontmatter {
        meta {
          title
          description
          image {
            publicURL
            name
          }
        }

        hero {
          title
          text
        }

        clients {
          logos {
            label
            value
          }
          title
        }

        services {
          items {
            icon {
              publicURL
              name
            }
            name
            text
          }
        }

        testimonial {
          title
        }

        project {
          title
          projects {
            value
          }
        }

        profits {
          heading
          text
          items {
            icon {
              publicURL
              name
            }
            name
            text
          }
        }
      }
    }
  }
`;
