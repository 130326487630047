import React from 'react';
import classNames from 'classnames';

import generateImgAlt from '../helpers/generateImgAlt';

const Reference = (props) => {
  const { avatar } = props;
  const isAvatar = avatar && avatar.length !== 0 && avatar.publicURL;
  const isLogo = props.authorLogo;
  const className = classNames('Reference', {
    'Reference--avatar': isAvatar || (isLogo && !isAvatar)
  });

  return (
    <div className={className}>
      <div className="Reference__header">
        {isAvatar ? (
          <div
            className="Reference__avatar"
            style={{backgroundImage: `url(${props.avatar.publicURL || props.avatar})`}}
          />
        ) : (
          <div
            className="Reference__avatar Reference__avatar--logo"
            style={{backgroundImage: `url(${props.authorLogo})`}}
          />
        )}
      </div>
      <div className="Reference__content">
        <p className="Reference__text">
          {props.text}
        </p>
        {(props.author.length > 0 || props.authorTitle.length > 0 || props.authorLogo.length > 0) &&
          <div className="Reference__content__bottom">
            <div className="Reference__author">{props.author}</div>
            <div className="Reference__authorTitle">{props.authorTitle}</div>
            {(isLogo && isAvatar) &&
              <img className="Reference__authorLogo" src={props.authorLogo} alt={generateImgAlt(props.authorLogo)} />
            }
          </div>
        }
      </div>
    </div>
  )
}

export default Reference;
