import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';

import Reference from '../components/Reference.jsx';

const ReferenceSwiper = (props) => {

  const params = {
    spaceBetween: 50,
    slidesPerView: 1,
    grabCursor: true,
    loop: true,
    loopedSlides: 2,
    loopAdditionalSlides: 100,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
        spaceBetween: 15
      },
      978: {
        slidesPerView: 2,
        spaceBetween: 30
      }
    }
  }

  return(
    <div className="ReferenceSwiper">
      <Swiper {...params}>
        { props.data.map((item, i) => (
          <div key={i}>
            <Reference {...item} />
          </div>
        )) }
      </Swiper>
    </div>
  )
}

export default ReferenceSwiper;
