import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import classNames from 'classnames';

const Markdown = ({ content, className = '' }) => (
  <div className={classNames('Markdown', className)}>
    <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]} />
  </div>
);

export default Markdown;
