import React from 'react';
import classNames from 'classnames';

const LinkClient = ({ url, children }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const LogoClient  = ({ logoMono, logoColor, name, colored }) => (
  <>
    {colored ? (
      <img
        src={logoColor}
        className="Clients__logo"
        alt={`${name} logo color`}
      />
    ) : (
      <>
        <img
          src={logoMono}
          className="Clients__logo"
          alt={`${name} logo`}
        />
        <img
          src={logoColor}
          className="Clients__logo Clients__logo--color"
          alt={`${name} logo color`}
        />
      </>
    )}
  </>
);

const Clients = ({ clients = [], heading = '', className = '', colored = false }) => (
  <div className={classNames(className, 'Clients')}>
    {heading && <h2 className="Clients__title">{heading}</h2>}
   
    <ul className="Clients__items">
      {clients && clients.map(client => {
        let props = {
          ...( client.position && client.position.length > 1 && 
            {style : {"transform": "translate(" + client.position + ")"}})
        };
        return (
          <li key={client.name} className="Clients__item" {...props}>
            {client.url ? (
              <LinkClient url={client.url}>
                <LogoClient
                  logoMono={client.logoMono}
                  logoColor={client.logoColor}
                  name={client.name}
                  colored={colored}
                />
              </LinkClient>
            ) : (
              <LogoClient
                logoMono={client.logoMono}
                logoColor={client.logoColor}
                name={client.name}
                colored={colored}
              />
            )}
          </li>
        )
      })}
    </ul>
  </div>
);

export default Clients;
