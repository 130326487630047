import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import generateImgAlt from '../helpers/generateImgAlt';
import ReferenceShort from './ReferenceShort';
import Button from './Button';

const propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  tagName: PropTypes.string,
  className: PropTypes.string,
  headingLevel: PropTypes.number,
  reference: PropTypes.object,
  variants: PropTypes.arrayOf(PropTypes.oneOf(['left', 'center', 'right'])),
};

const defaultProps = {
  text: '',
  tagName: 'article',
  className: '',
  headingLevel: 3,
  variants: [],
  titleWithText: false,
  reverse: false
};

function ProjectItem({
  imageSrc,
  title,
  text,
  tagName,
  className,
  titleWithText,
  headingLevel,
  variants,
  children,
  buttons,
  reference,
  reverse,
  technologies,
  ...otherProps
}) {
  const reverseClass = reverse ? 'ProjectItem--reverse' : '';
  const classes = classNames(className, 'ProjectItem', reverseClass);
  const TagName = tagName;
  const Heading = `h${headingLevel}`;
  const buttonsExist = buttons && buttons.length >= 1;

  return (
    <TagName className={classes} {...otherProps}>
      <div className="ProjectItem__image">
        {!titleWithText && 
          <Heading className="ProjectItem__title">{title}</Heading>
        }
      
        <img
          className="ProjectItem__image__inner" 
          src={imageSrc.publicURL || imageSrc} 
          alt={generateImgAlt(imageSrc.publicURL || imageSrc)}
        />
      </div>

      <div className="ProjectItem__content">
        <div className="ProjectItem__content__inner">
          {titleWithText && 
            <Heading className="ProjectItem__title ProjectItem__title--withText">{title}</Heading>
          }
          {(children || technologies) &&
            <p className="ProjectItem__text">
              {children && <span>{children}</span>}
              {(children && technologies) && <span><br/><br/></span>}
              {technologies && <span>Main technologies: <b>{technologies}</b></span>}
            </p>
          }

          {buttonsExist &&
            <div className="ProjectItem__btns">
              {buttons.map((item, i) => (
                <Button className="ProjectItem__btn" href={item.url} target="_blank" key={i}>
                  {item.text}
                </Button>
              ))}
            </div>
          }
        </div>
        {reference &&
          <div className="ProjectItem__content__reference">
            <ReferenceShort
              avatar={reference.avatar}
              text={reference.text}
              author={reference.author}
              authorTitle={reference.authorTitle}
            />
          </div>
        }
      </div>
    </TagName>
  );
}

ProjectItem.propTypes = propTypes;
ProjectItem.defaultProps = defaultProps;

export default ProjectItem;
