import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tagName: PropTypes.string,
  className: PropTypes.string,
  headingLevel: PropTypes.number,
  variants: PropTypes.arrayOf(PropTypes.oneOf(['center', 'large'])),
};

const defaultProps = {
  tagName: 'article',
  className: '',
  headingLevel: 3,
  variants: [],
};

function IconedText({
  iconSrc,
  title,
  children,
  tagName,
  className,
  headingLevel,
  variants,
}) {
  const variantClasses = variants.map(variant => `IconedText--${variant}`);
  const classes = classNames(className, 'IconedText', variantClasses);
  const TagName = tagName;
  const Heading = `h${headingLevel}`;

  return (
    <TagName className={classes}>
      <div className="IconedText__content">
        <img src={iconSrc} className="IconedText__icon" alt={title} />
        
        <Heading className="IconedText__title">{title}</Heading>

        {children}
      </div>
    </TagName>
  );
}

IconedText.propTypes = propTypes;
IconedText.defaultProps = defaultProps;

export default IconedText;
